<template>
  <div style="margin: 0 auto">
    <canvas ref="chartCanvas"></canvas>
    <p class="percentages">{{ total || 0 }}</p>
    <p class="percentagetxt">Total</p>
  </div>
</template>

<script>
  import { ref, onMounted, computed, watch } from 'vue';
  import { Chart, registerables } from 'chart.js';

  Chart.register(...registerables);

  export default {
    props: {
      violationReport: Object,
    },
    setup(props) {
      const chartCanvas = ref(null);
      let chart = null;

      const chartData = computed(() => {
        if (
          !props.violationReport.placardCount &&
          !props.violationReport.suspectedMisuseCount &&
          !props.violationReport.illegallyParkedCount
        ) {
          return [1, 1, 1];
        }
        return [
          props.violationReport.placardCount || 0,
          props.violationReport.suspectedMisuseCount || 0,
          props.violationReport.illegallyParkedCount || 0,
        ];
      });

      const total = computed(() => {
        if (
          !props.violationReport.placardCount &&
          !props.violationReport.suspectedMisuseCount &&
          !props.violationReport.illegallyParkedCount
        ) {
          return 0;
        }
        return (
          props.violationReport.placardCount +
          props.violationReport.suspectedMisuseCount +
          props.violationReport.illegallyParkedCount
        );
      });

      onMounted(() => {
        const ctx = chartCanvas.value.getContext('2d');

        chart = new Chart(ctx, {
          type: 'doughnut',

          data: {
            labels: [
              'No Placard',
              'Suspected Misuse',
              'Parked Illegally',
            ],
            datasets: [
              {
                label: 'slices',
                data: chartData.value,
                backgroundColor: ['#95D5FB', '#006AA7', '#F5F5F5'],
                borderColor: ['#fff', '#fff', '#fff', '#fff'],
              },
            ],
          },
          options: {
            responsive: true,

            maintainAspectRatio: false,
            cutoutPercentage: 80,
            plugins: {
              datalabels: {
                formatter: function (value, context) {
                  return context.chart.data.labels[context.dataIndex];
                },
              },

              legend: {
                display: false,
              },
            },
            tooltips: {
              enabled: true,
            },
            hover: {
              mode: true,
            },
          },
        });
      });
      watch(chartData, (newData) => {
        if (chart) {
          chart.data.datasets[0].data = newData;
          chart.update();
        }
      });

      return { chartCanvas, total };
    },
  };
</script>

<style scoped>
  div {
    display: block;
    background-color: rgb(255, 255, 255);
    width: 220px;
    height: 220px;
    /* top: 0px; */
    /* position: absolute; */
    /* left: 10%; */
  }
  .percentages {
    /* top: 90px; */
    font-size: 24px;
    /* right: 10px; */
    bottom: 60%;
    /* left: 82px; */
    font-weight: bold;
    position: relative;
  }
  .percentagetxt {
    font-size: 16px;
    font-weight: bold;
    color: gray;
    bottom: 60%;

    /* left: 82px; */
    position: relative;
  }
</style>
