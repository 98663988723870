<template>
  <div>
    <canvas ref="chartCanvas"></canvas>
  </div>
</template>

<script>
import { ref, onMounted, computed, watch } from "vue";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

export default {
  props: {
    tripsWithWaitCount: Number,
  },
  setup(props) {
    const chartCanvas = ref(null);
    let chart = null;

    const chartData = computed(() => {
      if (!props.tripsWithWaitCount) {
        return 10;
      }
      return props.tripsWithWaitCount || 10;
    });

    onMounted(() => {
      const ctx = chartCanvas.value.getContext("2d");

      chart = new Chart(ctx, {
        type: "line",
        data: {
          labels: [""],
          datasets: [
            {
              barThickness: 100,
              clip: 0,
              minBarLength: chartData.value,
              grouped: false,
              label: "..................................",
              backgroundColor: "#3e97ca",
              data: [0],
              borderRadius: 2,
              indexAxis: "x",
              normalized: true,
              stack: "#ebe0fd",
              type: "bar",
              pointStyle: "line",
              barPercentage: 1,
              categoryPercentage: 1,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          backgroundColor: "#ebe0fd",
          layout: {
            padding: 0,
            autoPadding: false,
          },
          scales: {
            x: {
              display: false,
              title: {
                display: false,
                text: "Data Sets",
              },
            },
            y: {
              display: false,
              title: {
                display: false,
                text: "Values",
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false, // Hide the legend label
          },
        },
      });
    });

    watch(chartData, (newData) => {
      if (chart) {
        chart.data.datasets[0].minBarLength = newData;
        chart.update();
      }
    });

    return { chartCanvas };
  },
};
</script>

<style scoped>
div {
  display: block;
  background-color: #ebe0fd;
  width: 75px;
  height: 92px;
  /* top: 12px; */
  /* position: absolute; */
  /* left: 0%; */
}

canvas {
  max-width: 100%; /* Adjust as needed */
  display: none;
}
</style>
