<template>
  <div class="topSection">
    <div class="topLeft">
      <div class="cards">
        <div class="card card2">
          <h2 class="heading">Total Trips</h2>
          <p>Idle Carbons (tons): {{ idleCarbonValue }}</p>
          <h2 class="count">{{ totalTripsCount }}</h2>
        </div>
        <!-- <div class="card card2">
          <h2 class="heading" style="font-size: 21px">
            Logged Trips
          </h2>
          <div class="second">
            <div class="secondContent">
              <div class="content1">
                <div class="boxColor">
                  <div class="box box1"></div>
                  <p style="font-weight: 600">Manual:</p>
                </div>
                <p style="font-weight: 600">
                  {{ loggedTripsPayload.manual || 0 }}
                </p>
              </div>
              <div class="content1">
                <div class="boxColor">
                  <div class="box box2"></div>
                  <p style="font-weight: 600">Automatic:</p>
                </div>
                <p style="font-weight: 600">
                  {{ loggedTripsPayload.automatic || 0 }}
                </p>
              </div>
            </div>
           
            <RoundProgressChart :manual="loggedTripsPayload.manual" :automatic="loggedTripsPayload.automatic" />
          </div>
        </div> -->
        <div class="card card2">
          <h2 class="heading" style="font-size: 21px">
            No Way ADA
          </h2>
          <!-- <p>Average per day: {{ avgPerDay }}</p> -->
          <div class="content1">
            <h2 class="count" style="margin-top: revert;">
              {{ NoWayAdaCount }}
            </h2>
            <!-- <img src="./images/two.png" alt="" /> -->
            <!-- <BarChartTripsVisit :tripsWithNoWaitCount="totalTripsCount - tripsWithWaitCount
              " /> -->
          </div>
        </div>
      </div>
      <div class="cards">
        <div class="card card2">
          <h2 class="heading" style="font-size: 21px">
            Trips with Issues
          </h2>
          <!-- <p>Wait per trip: {{ avgWaitPerTrip }}</p> -->
          <p>Issues per day: {{ avgIssuesAbandonedTripPerDay }}</p>
          <div class="content1">
            <h2 class="count">{{ (tripsWithWaitCount + (abandonedTripsPayload.count || 0)) }}</h2>
            <!-- <img src="./images/two.png" alt="" /> -->
            <BarChart :tripsWithWaitCount="(tripsWithWaitCount + (abandonedTripsPayload.count || 0))" />
          </div>
        </div>
        <div class="card card2">
          <h2 class="heading" style="font-size: 21px">
            Trips Without Issues
          </h2>
          <!-- <p>Average per day: {{ avgPerDay }}</p> -->
          <div class="content1">
            <h2 class="count" style="margin-top: revert;">
              {{ totalTripsCount - tripsWithWaitCount }}
            </h2>
            <!-- <img src="./images/two.png" alt="" /> -->
            <!-- <BarChartTripsVisit :tripsWithNoWaitCount="totalTripsCount - tripsWithWaitCount
              " /> -->
          </div>
        </div>
      </div>
    </div>
    <div class="topRight">
      <div class="smallCard">
        <div class="card2 card" style="
            display: flex;
            align-items: center;
            justify-content: center;
          ">
          <h3 class="heading" style="margin: 0px">
            Contributing Users: {{ totalDevicesCount }}
          </h3>
        </div>
        <div class="card2 card" style="
            display: flex;
            align-items: center;
            justify-content: center;
          ">
          <h3 class="heading" style="margin: 0px">
            Abandoned Trips: {{ abandonedTripsPayload.count || 0 }}
            <span>
              {{ `(${abandonedTripsPayload.totalAmount || 0}$)` }}
            </span>
          </h3>
        </div>
      </div>
      <!-- TODO: integration -->
      <div class="mapCard card">
        <div class="mapLeft">
          <h2>Events</h2>
          <!-- <img :src="map" alt="" /> -->
          <div class="zipCodeInput">
            <input type="text" v-model="zipCode" placeholder="Enter Zip Code" />
            <button @click="goToZipCode">Go</button>
          </div>
          <GoogleMap api-key="AIzaSyDj43bOKdYgSVJ6fYysvYpEZwXEs_jvpIk" style="width: 100%; height: 250px"
            :center="center" :zoom="zoom">
            <Marker v-for="(event, index) in events" :key="index"
              :options="{ position: event.center, icon: getMarkerIcon(event) }">
              <InfoWindow :options="{
                position: event.center,
              }">
                <div class="event-detail-container">
                  <div class="event-data-wrapper">
                    <strong>Type:</strong>
                    <span>{{ event.violationType || 'None' }}</span>
                  </div>
                  <div class="event-data-wrapper">
                    <strong>Address:</strong>
                    <span>{{ event.location }}</span>
                  </div>
                  <div class="event-data-wrapper">
                    <strong>Location:</strong>
                    <span>{{
                      `${(event.latitude || event.lat).toFixed(6)}, ${(
                        event.logitude || event.longitude || event.lon
                      ).toFixed(6)}`
                    }}</span>
                  </div>
                </div>
              </InfoWindow>
            </Marker>
          </GoogleMap>
        </div>
        <div class="mapRight">
          <div v-for="(item, index) in reports[currentIndex]" :key="index" class="reportIcon_Desktop flex items-center"
            style="display: flex; align-items: center; gap: 0">
            <div class="repo_Desktop" style="display: flex; align-items: center">
              <img :src="item.image" alt="" v-if="item.image" />
              <p style="
                  font-weight: 600;
                  margin-right: 10px;
                  font-weight: 600;
                  font-size: 19px;
                ">
                {{ item.title }}
              </p>
            </div>
            <p v-if="item.subtitle" style="
                margin-left: auto;
                font-weight: 600;
                font-size: 16px;
              ">
              {{ item.subtitle }}
            </p>
          </div>

          <!-- Navigation points for carousel -->
          <div class="points">
            <span v-for="(slide, index) in reports" :key="index" @click="changeSlide(index)" :style="{
              background:
                currentIndex === index ? '#006aa7' : 'transparent',
            }"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="cards">
    <div
      class="card"
      style="
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      "
    >
      <h2 class="heading">Total Trips</h2>
      <h2 class="count">1652</h2>
    </div>
    <div
      class="card"
      style="
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      "
    >
      <h2 class="heading">Contributing Users</h2>
      <h2 class="count">3</h2>
    </div>
    <div
      class="card"
      style="
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      "
    >
      <h2 class="heading">Idle Carbons</h2>
      <h2 class="count">12</h2>
    </div>
    <div
      class="card"
      style="
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      "
    >
      <h2 class="heading">Abondoned Trips</h2>
      <div class="price">
        <h2 class="count">22</h2>
        <p>2000$</p>
      </div>
    </div>
  </div>

  <div class="cards">
    <div class="card card2">
      <h2>Manually Logged Trips</h2>
      <div class="cardImg">
        <h2 class="count">652</h2>
        <RoundProgressChart />
      </div>
    </div>
    <div class="cardLog">
      <h2>Auto Logged Trips</h2>
      <div class="cardImg">
        <h2 class="count">1000</h2>
        <AutoLogTripChart />
      </div>
    </div>
    <div class="card">
      <h2>NoWay ADA Reports</h2>
      <div class="cardImg" style="gap: 10px">
        <div>
          <p>Average per day : 1.1%</p>
          <h2 class="count">1370</h2>
        </div>
        <div style="position: relative">
          <div class="div3">{{ tripsWithNoWaitGraphStart }}</div>

          <BarChart />
          <div class="div4">{{ tripsWithNoWaitGraphEnd }}</div>
        </div>
      </div>
    </div>
    <div class="card">
      <h2>Trips with issues</h2>
      <div class="cardImg" style="gap: 10px">
        <div>
          <p>Average wait per trip : 2min</p>
          <h2 class="count">200</h2>
        </div>
        <div style="position: relative">
          <div class="div3">{{ tripsWithNoWaitGraphStart }}</div>

          <BarChartTripsVisit />
          <div class="div4">{{ tripsWithNoWaitGraphEnd }}</div>
        </div>
      </div>
    </div>
  </div> -->
</template>

<script>
/* global google */
import axios from 'axios';
import Group55 from '../../assets/group-55.svg';
import Group551 from '../../assets/group-551.svg';
import map from '../../assets/map.png';
import map1 from '../../assets/map1.png';
import map2 from '../../assets/map2.png';
import map3 from '../../assets/map3.png';

// import RoundProgressChart from '@/components/chats/RoundProgressChart.vue';
// import AutoLogTripChart from "@/components/chats/AutoLogTripChart.vue";
import BarChart from '../chats/BarChart.vue';
// import BarChartTripsVisit from '../chats/BarChartTripsVisit.vue';
import { GoogleMap, Marker, InfoWindow } from 'vue3-google-map';
import { ref, watch } from 'vue';

export default {
  components: {
    // RoundProgressChart,
    // AutoLogTripChart,
    BarChart,
    // BarChartTripsVisit,
    GoogleMap,
    Marker,
    InfoWindow,
  },
  props: {
    events: {
      type: Array,
      default: () => [],
    },
    totalTripsCount: {
      type: Number,
      default: () => 0,
    },
    idleCarbonValue: {
      type: Number,
      default: () => 0,
    },
    avgWaitPerTrip: {
      type: String,
      default: () => '0 min',
    },
    avgPerDay: {
      type: String,
      default: () => '0 min',
    },
    NoWayAdaCount: {
      type: Number,
      default: () => 0,
    },
    avgIssuesAbandonedTripPerDay: {
      type: String,
      default: () => '0 issues/day',
    },
    reportedTripsPayload: {
      type: Object,
      default: () => { },
    },
    violationReportsPayload: {
      type: Object,
      default: () => { },
    },
    loggedTripsPayload: {
      type: Object,
      default: () => { },
    },
    tripsWithWaitCount: {
      type: Number,
      default: () => 0,
    },
    totalDevicesCount: {
      type: Number,
      default: () => 0,
    },
    abandonedTripsPayload: {
      type: Object,
      default: () => { },
    },
  },
  computed: {
    reports() {
      return [
        [
          {
            title: 'Total Reported Trips',
            subtitle: this.reportedTripsPayload.total || 0,
          },
          {
            image: map1,
            title: 'Average Trip Duration',
            subtitle: `${this.reportedTripsPayload.avgTripDuration}\n(min:sec)`,
          },
          {
            image: map2,
            title: 'Busiest day of the Week',
            subtitle: this.reportedTripsPayload.busiestDayOfWeek,
          },
          {
            image: map3,
            title: 'Average Wait Time',
            subtitle: `${this.reportedTripsPayload.avgWaitTime}\n(min:sec)`,
          },
        ],
        [
          {
            title: 'Total Reported Violations',
            subtitle: this.violationReportsPayload.total || '0',
          },
          {
            image: map1,
            title: 'Most Type',
            subtitle: `${this.violationReportsPayload.mostType.value} (${this.violationReportsPayload.mostType.count})`,
          },
          {
            image: map2,
            title: 'Most Frequent Day of Week',
            subtitle: `${this.violationReportsPayload.mostWeekDay.value} (${this.violationReportsPayload.mostWeekDay.count})`,
          },
          {
            image: map3,
            title: 'Most Frequent Time',
            subtitle: `${this.violationReportsPayload.mostTimeOfDay.value} (${this.violationReportsPayload.mostTimeOfDay.count})`,
          },
        ],
      ];
    },
  },
  setup(props) {
    const center = { lat: 40.689247, lng: -74.044502 };
    const zoom = ref(1);
    const zipCode = ref('');

    watch(() => props.events, (newEvents) => {
      handleEventsChange(newEvents);
    }, { deep: true });

    const handleEventsChange = (events) => {
      console.log(events);
      zoom.value = 1;
      zipCode.value = '';
    }

    const getMarkerIcon = (event) => {
      let iconColor;
      if (event.state === 'No wait') {
        iconColor = 'purple';
      } else if (event.state === 'Violation') {
        iconColor = 'red';
      } else if (event.state === 'End wait') {
        iconColor = 'pink';
      } else if (event.state === 'Abandon') {
        iconColor = 'grn';
      } else if (Object.keys(event).includes('lat')) {
        iconColor = 'ylw';
      } else {
        iconColor = 'orange';
      }

      return {
        url: `http://maps.google.com/mapfiles/kml/paddle/${iconColor}-circle.png`,
        scaledSize: new google.maps.Size(32, 32),
      };
    };

    return { center, zoom, zipCode, getMarkerIcon };
  },
  methods: {
    changeSlide(index) {
      this.currentIndex = index;
    },
    async goToZipCode() {
      try {
        this.zoom = 1;
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${this.zipCode}&key=AIzaSyDj43bOKdYgSVJ6fYysvYpEZwXEs_jvpIk`
        );
        const location = response.data.results[0].geometry.location;
        this.center = { lat: location.lat, lng: location.lng };
        this.zoom = 11;
      } catch (error) {
        console.error('Error fetching location:', error);
        alert('Invalid Zip Code. Please try again.');
      }
    },
  },
  data() {
    return {
      map,
      map1,
      map2,
      map3,

      Group55,
      Group551,
      // totalTrips: 1652,
      totalDevices: 3,
      idleCarbonsTons: 12,
      abandonedTrips: 22,
      abandonedTripsCost: '2000$',
      manuallyLoggedTripsPercentage: '24%',
      manuallyLoggedTripsCount: 652,
      autoLoggedTripsPercentage: '76%',
      autoLoggedTripsCount: 1000,
      tripsWithNoWait: 1370,
      averagePerDay: 'Average per day: 1.1%',
      tripsWithNoWaitPercentage: '82.9%',
      tripsWithNoWaitGraphStart: 0,
      tripsWithNoWaitGraphEnd: 100,
      tripsWithWait: 200,
      averageWaitPerTrip: '2 min',
      tripsWithWaitPercentage: '12.1%',
      tripsWithWaitGraphStart: 0,
      tripsWithWaitGraphEnd: 100,
      currentIndex: 0, // Index of the currently displayed report
    };
  },
};
</script>

<style>
.doughnut-chart {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #f8f8f8;
  overflow: hidden;
}

.doughnut-chart::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  clip: rect(0, 75px, 150px, 0);
  border-radius: 50%;
  background: conic-gradient(#36a2eb 0% 45%, transparent 0% 100%);
}

.percentage {
  position: absolute;
  /* top: 50%; */
  left: 65%;
  /* transform: translate(-50%, -50%); */
  /* font-size: 24px; */
  color: #333;
}

.zipCodeInput {
  display: flex;
  margin-bottom: 20px;
}

.zipCodeInput input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 200px;
  margin-right: 10px;
}

.zipCodeInput button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  background-color: #006aa7;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.zipCodeInput button:hover {
  background-color: #005a96;
}
</style>
