<template>
  <div class="main">
    <!-- Conditionally render the drawer/modal -->

    <div v-if="showDrawer" class="mobile_main">
      <div class="mobile_header">
        <h3>Filters</h3>
        <a>
          <i
            @click="closeDrawer"
            class="fa-solid fa-times"
            style="color: white"
          ></i>
        </a>
      </div>
      <div style="width: 100%">
        <button
          v-if="!isQuickView"
          style="
            width: 80%;
            margin: 0 auto;
            margin-top: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
          "
          class="quickViewBtn"
          @click="onQuickViewClick"
        >
          Quick View
        </button>
        <button
          v-if="isQuickView"
          style="
            width: 80%;
            margin: 0 auto;
            margin-top: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
          "
          class="quickViewBtn"
          @click="onQuickViewResetClick"
        >
          Reset View
        </button>
      </div>

      <div class="mobile_content">
        <div class="filterCardMobile">
          <ul>
            <div class="head">
              <h3>Events</h3>
              <p>Select All</p>
            </div>
            <li
              v-for="(event, index) in events"
              :key="index"
              @click="toggleOption(event, index, selectedEvents)"
            >
              <input
                type="checkbox"
                v-model="selectedEvents[index]"
              />
              <p style="margin: 0">{{ event }}</p>
              <img
                v-if="selectedEvents[index]"
                :src="Tick"
                class="tick"
              />
            </li>
            <!-- <li>
              <input type="checkbox" />
              <p>Parkings</p>

              <img :src="Tick" class="tick" />
            </li>
            <li>
              <input type="checkbox" />
              <p>Placard</p>
              <img :src="Tick" class="tick" />
            </li>
            <li>
              <input type="checkbox" />
              <p>Misuse</p>
              <img :src="Tick" class="tick" />
            </li>
            <li>
              <input type="checkbox" />
              <p>Theft</p>
              <img :src="Tick" class="tick" />
            </li> -->
          </ul>
        </div>
        <div class="filterCardMobile">
          <ul>
            <div class="head">
              <h3>States</h3>
              <p>Select All</p>
            </div>
            <li
              v-for="(state, index) in states"
              :key="index"
              @click="toggleStateOption(state, index, selectedStates)"
            >
              <input
                type="checkbox"
                v-model="selectedStates[index]"
              />
              <p style="margin: 0">{{ state }}</p>
              <img
                v-if="selectedStates[index]"
                :src="Tick"
                class="tick"
              />
            </li>
          </ul>
        </div>
        <div class="filterCardMobile" v-if="isQuickView">
          <ul>
            <div class="head">
              <h3>Date Range</h3>
            </div>
            <li
              v-for="(date, index) in dateOptions"
              :key="index"
              @click="toggleDateRangeOption(date)"
            >
              <input
                type="checkbox"
                :checked="selectedDateRange === time"
              />
              <p style="margin: 0">{{ date }}</p>
              <img
                v-if="selectedDateRange === date"
                :src="Tick"
                class="tick"
              />
            </li>
          </ul>
        </div>
        <!-- Date Card -->
        <div class="filterCardMobile" v-if="!isQuickView">
          <div class="head">
            <h3>Starting Date</h3>
          </div>
          <div class="date_option">
            <div class="date1Mobile">
              <h4 class="yearMobile">Year:</h4>

              <select
                v-model="selectedYear"
                @change="updateStartDate"
              >
                <option
                  v-for="year in years"
                  :key="year"
                  :value="year"
                >
                  <span
                    >{{ year }}
                    <i class="fa-solid fa-chevron-down"></i
                  ></span>
                </option>
              </select>
            </div>
            <div class="date1Mobile">
              <h4 class="yearMobile">Month:</h4>
              <select
                v-model="selectedMonth"
                @change="updateStartDate"
              >
                <option
                  v-for="(month, index) in months"
                  :key="index"
                  :value="month"
                >
                  {{ month }}
                </option>
              </select>
            </div>
            <div class="date1Mobile">
              <h4 class="yearMobile">Date:</h4>
              <select
                v-model="selectedDate"
                @change="updateStartDate"
              >
                <option
                  v-for="date in dates"
                  :key="date"
                  :value="date"
                >
                  {{ date }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="filterCardMobile" v-if="!isQuickView">
          <div class="head">
            <h3>Ending Date</h3>
          </div>
          <div class="date_option">
            <div class="date1Mobile">
              <h4 class="yearMobile">Year:</h4>
              <span
                >2023 <i class="fa-solid fa-chevron-down"></i
              ></span>
            </div>
            <div class="date1Mobile">
              <h4 class="yearMobile">Month:</h4>
              <span
                >November <i class="fa-solid fa-chevron-down"></i
              ></span>
            </div>
            <div class="date1Mobile">
              <h4 class="yearMobile">Date:</h4>
              <span>22 <i class="fa-solid fa-chevron-down"></i></span>
            </div>
          </div>
        </div>

        <div class="filterCardMobile">
          <ul>
            <div class="head">
              <h3>Time</h3>
            </div>
            <li v-for="(time, index) in timeOptions" :key="index">
              <input
                type="checkbox"
                @click="toggleTimeOption(time)"
                :checked="selectedTime === time"
              />
              <p style="margin: 0">{{ time }}</p>
              <img
                v-if="selectedTime === time"
                :src="Tick"
                class="tick"
              />
            </li>
          </ul>
        </div>
        <!-- Time Card -->
        <div
          v-if="selectedTime === 'Custom'"
          class="filterCardMobile"
        >
          <div class="date_option">
            <div class="time">
              <h3 style="font-size: 16.399px; font-weight: 700">
                Starting Time
              </h3>
              <div class="date1Mobile">
                <input type="number" placeholder="22" />:
                <input type="number" placeholder="22" />
              </div>
            </div>
            <div class="time">
              <h3 style="font-size: 16.399px; font-weight: 700">
                Ending Time
              </h3>
              <div class="date1Mobile">
                <input type="number" placeholder="22" />:
                <input type="number" placeholder="22" />
              </div>
            </div>
          </div>
        </div>

        <!-- Age Card -->
        <div class="filterCardMobile">
          <ul>
            <div class="head">
              <h3>
                Age <span>{{ selectedAgeValue }}(Years)</span>
              </h3>
            </div>
            <li v-for="(age, index) in ageOptions" :key="index">
              <input
                type="checkbox"
                @click="toggleAgeOption(age)"
                :checked="selectedAge === age"
              />
              <p style="margin: 0">{{ age }}</p>
              <img
                v-if="selectedAge === age"
                :src="Tick"
                class="tick"
              />
            </li>
          </ul>
        </div>
        <!-- <div class="filterCardMobile">
          <div class="head">
            <h3>
              Age <span>{{ selectedAgeValue }}(Years)</span>
            </h3>

            <div class="date_option">
              <input
                type="range"
                id="age"
                name="age"
                min="0"
                max="100"
                v-model="selectedAgeValue"
              />
            </div>
          </div>
        </div> -->

        <!-- Age Card -->
        <div class="filterCardMobile">
          <div class="head">
            <h3>Days</h3>
          </div>
          <div class="date_option">
            <span
              v-for="(day, index) in days"
              :key="index"
              :class="{ 'selected-day': isSelectedDay(day) }"
              @click="selectDay(day)"
            >
              {{ day }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="drawer-content" style="width: 100%">
        <div class="card">
          <div class="card-container">
            <h4
              style="
                color: black;
                padding-left: 20px;
                border-bottom: 1px solid #707a8c;
              "
            >
              Events
            </h4>
            <ul
              style="
                display: flex;
                gap: 24px;
                align-items: start;
                margin-top: 10px;
                gap: 24px;
                list-style: none;
                flex-direction: column;
              "
            >
              <li
                v-for="option in filterOptions"
                :key="option.value"
                style="display: flex; gap: 14px; position: relative"
                class="ListCard"
              >
                <input
                  type="checkbox"
                  :id="option.value"
                  v-model="selectedFilters"
                  :value="option.value"
                  class="custom-checkbox"
                />
                <label style="color: #707a8c" :for="option.value">
                  {{ option.label }}

                  <img
                    :src="Tick"
                    v-if="selectedFilters.includes(option.value)"
                    class="fa-solid fa-check"
                    style="
                      position: absolute;
                      left: 20px;
                      height: 24px;
                      bottom: 16px;
                    "
                  />
                </label>
              </li>
            </ul>
          </div>
        </div>
        <div class="card">
          <div class="card-container">
            <h4
              style="
                color: black;
                padding-left: 20px;
                border-bottom: 1px solid #707a8c;
              "
            >
              States
            </h4>
            <ul
              style="
                display: flex;
                gap: 24px;
                align-items: start;
                margin-top: 10px;
                gap: 24px;
                list-style: none;
                flex-direction: column;
              "
            >
              <li
                v-for="option in filterStateOptions"
                :key="option.value"
                style="display: flex; gap: 14px; position: relative"
                class="ListCard"
              >
                <input
                  type="checkbox"
                  :id="option.value"
                  v-model="selectedStateFilters"
                  :value="option.value"
                  class="custom-checkbox"
                />
                <label style="color: #707a8c" :for="option.value">
                  {{ option.label }}

                  <img
                    :src="Tick"
                    v-if="selectedStateFilters.includes(option.value)"
                    class="fa-solid fa-check"
                    style="
                      position: absolute;
                      left: 20px;
                      height: 24px;
                      bottom: 16px;
                    "
                  />
                </label>
              </li>
            </ul>
          </div>
        </div>
      </div> -->
    <!-- <SideBar /> -->
    <div class="content" v-if="!showDrawer">
      <!-- Mobile screen filter -->

      <div class="mobile">
        <div class="mobile_filter">
          <button>
            <i class="fa-solid fa-bars" @click="toggleDrawer"></i>
          </button>
          <h2>ParkingNSite</h2>
          <button>
            <i class="fa-solid fa-filter" @click="toggleDrawer"></i>
          </button>
        </div>

        <h2 class="dashboard_name">Executive Dashboard</h2>
        <h2 class="dashboard_run">Version: 2.0</h2>
      </div>
      <div class="filter_main">
        <div
          style="
            display: flex;
            justify-content: space-between;
            width: 100%;
            border-bottom: 1px solid black;
            margin-bottom: 0;
          "
        >
          <div style="display: flex; margin-left: 30px;align-items: center;">
            <img :src="require('../assets/logo.png')" style="width: 220px;" />
            <h1 style="margin-top: 5px; font-weight: 700; font-size: 20px;color: '#121212'">Executive Dashboard</h1>
          </div>
          <div style="display: flex; gap: 1em">
            <h1 style="margin-top: 16px">Dashboard Version: 2.0</h1>
            <button
              v-if="!isQuickView"
              class="quickViewBtn"
              @click="onQuickViewClick"
            >
              Quick View
            </button>
            <button
              v-if="isQuickView"
              class="quickViewBtn"
              @click="onQuickViewResetClick"
            >
              Reset View
            </button>
          </div>
        </div>

        <Filters
          :isQuickView="isQuickView"
          @startDateChanged="startDateChanged"
          @endDateChanged="endDateChanged"
          @eventTypeChanged="eventTypeChanged"
          @tripStateChanged="tripStateChanged"
          @selectedDaysArrayChanged="selectedDaysArrayChanged"
          @dateRangeSelected="dateRangeSelected"
          @userAgeChanged="userAgeChanged"
          @startTimeChanged="startTimeChanged"
          @endTimeChanged="endTimeChanged"
        />
      </div>
      <Statistics
        :events="tripEvents"
        :totalTripsCount="totalTripsCount"
        :idleCarbonValue="idleCarbonValue"
        :avgWaitPerTrip="avgWaitPerTrip"
        :avgPerDay="avgPerDay"
        :NoWayAdaCount="NoWayAdaCount"
        :avgIssuesAbandonedTripPerDay="avgIssuesAbandonedTripPerDay"
        :reportedTripsPayload="reportedTripsPayload"
        :violationReportsPayload="violationReportsPayload"
        :loggedTripsPayload="loggedTripsPayload"
        :tripsWithWaitCount="tripsWithWaitCount"
        :totalDevicesCount="totalDevicesCount"
        :abandonedTripsPayload="abandonedTripsPayload"
      />
      <VoilationReport :violationReport="violationReport" />
    </div>
  </div>
</template>

<script>
  import Statistics from '@/components/Statistics/DashboardStats.vue';
  import VoilationReport from '@/components//VoilationReport/VoilationReport.vue';
  import Filters from '@/components/shared/DashboardFilters.vue';

  import Image from '@/assets/vector.svg';
  import Image46 from '@/assets/vector-46.svg';
  import Tick from '@/assets/VectorTick.svg';
  import { inject } from '@vue/runtime-core';
  import moment from 'moment';

  let FirebaseService = {};
  export default {
    components: {
      Statistics,
      VoilationReport,
      Filters,
    },
    data() {
      return {
        Image,
        Image46,
        Tick,
        showDrawer: false,
        selectedAgeValue: 2,
        selectedDays: [],
        days: [
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
        ],
        filterOptions: [
          { value: 'Parking', label: 'Parking' },
          { value: 'Placard', label: 'Placard' },
          { value: 'Misuse', label: 'Misuse' },
          { value: 'Theft', label: 'Theft' },
        ],
        filterStateOptions: [
          { value: 'California', label: 'California' },
          { value: 'Texas', label: 'Texas' },
          { value: 'Florida', label: 'Florida' },
          { value: 'Hawaii', label: 'Hawaii' },
          { value: 'Alaska', label: 'Alaska' },
          { value: 'Ohio', label: 'Ohio' },
        ],
        selectedFilters: [],
        selectedStateFilters: [],
        events: [
          'Illegally Parked',
          'No Placard',
          'Suspected Placard Misuse',
        ],
        states: [
          'California',
          'Texas',
          'Florida',
          'Hawaii',
          'Alaska',
          'Ohio',
        ],
        dateOptions: [
          'This Week',
          'This Month',
          'Last Month',
          'Year To Date',
        ], // Replace with actual states
        // Replace with actual states
        timeOptions: [
          'Business Hours',
          'After Hours',
          'Custom',
          'All',
        ], // Replace with actual states
        ageOptions: ['Above 65', '65 and below', 'All'], // Replace with actual states

        selectedEvents: Array(4).fill(false), // Adjust the size based on the number of events
        selectedStates: Array(4).fill(false),
        selectedTime: 'Business Hours',
        selectedDateRange: 'This Week',

        selectedYear: '2023',
        selectedMonth: 'November',
        selectedDate: '22',
        years: ['2023', '2024', '2025'], // Replace with actual years
        months: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ],
        // For simplicity, assuming 31 days for each month
        dates: Array.from({ length: 31 }, (_, index) =>
          (index + 1).toString()
        ),
        isQuickView: false,
        firebasePayload: {},
        selectedStartDate: '',
        selectedEndDate: '',
        eventType: '',
        state: '',
        daysArray: [],
        dateRange: [
          'This Week',
          'This Month',
          'Last Month',
          'Year to Date',
        ],
        selectedAge: '',
        selectedStartTime: '',
        selectedEndTime: '',
      };
    },
    computed: {
      totalTrips() {
        let trips = this.firebasePayload.totalTrips || [];
        if (this.selectedState && this.selectedState !== 'All') {
          trips = trips.filter(
            (trip) => trip.stat === this.selectedState
          );
        }
        if (
          this.selectedDaysArray.length > 0 &&
          this.selectedDaysArray.length !== 7
        ) {
          trips = trips.filter((trip) =>
            this.selectedDaysArray.includes(
              trip.weekDay?.toLowerCase() || ''
            )
          );
        }
        if (this.selectedUserAge !== 'All') {
          trips = trips.filter(
            (trip) => trip.userAge === this.selectedUserAge
          );
        }
        if (
          this.selectedStartTimeFilter &&
          this.selectedEndTimeFilter
        ) {
          trips = trips.filter((trip) =>
            this.isBetweenStartAndEndTime(trip.startTime)
          );
        }
        return trips;
      },
      NoWayAdaCount() {
        return this.firebasePayload.nowayadaCount
      },
      tripEvents() {
        return (
          this.totalTrips
            ?.map((trip) => ({
              ...trip,
              center: {
                lat: trip.latitude || trip.lat || 0,
                lng: trip.logitude || trip.longitude || trip.lon || 0,
              },
            }))
            .filter((trip) => (trip.latitude || trip.lat) && (trip.logitude || trip.longitude || trip.lon)) || []
        );
      },
      totalTripsCount() {
        return this.totalTrips.length || 0;
      },
      idleCarbonValue() {
        const trips = this.totalTrips;
        if (!trips || !trips.length) return 0;

        const sumOfWaitTime = trips.reduce((acc, trip) => {
          if (!trip.waitTime) return acc;
          const waitTimeMoment = moment.duration(trip.waitTime);
          return acc + waitTimeMoment.asSeconds();
        }, 0);

        const value = sumOfWaitTime / 3600 / 4 / 2000;
        return +value.toFixed(6);
      },
      avgWaitPerTrip() {
        const trips = this.totalTrips;
        if (!trips || !trips.length) return '0 min';

        const totalWaitTime = trips.reduce((acc, trip) => {
          if (!trip.waitTime) {
            return acc;
          }
          const waitTimeMoment = moment.duration(trip.waitTime);
          return acc + waitTimeMoment.asSeconds();
        }, 0);

        const avgWaitTime = totalWaitTime / trips.length;
        const avgDuration = moment.duration(avgWaitTime, 'seconds');

        return `${
          Math.round(avgDuration.asMinutes() * 100) / 100
        } min`;
      },
      avgIssuesAbandonedTripPerDay() {
    const trips = this.totalTrips;

    if (!trips || !trips.length) return '0 issues/day';

    const tripsWithIssues = trips.filter(trip => 
        trip.state === 'Abandon' || trip.wait === 'Yes'
    );

    let tripsPerDay = {};
    let earliestDate = moment();
    let latestDate = moment();

    tripsWithIssues.forEach(trip => {
        const year = trip.year;
        const month = trip.month.padStart(2, '0'); 
        const day = trip.day.toString().padStart(2, '0'); 

        const tripDateString = `${year}-${month}-${day}`;
        const tripDate = moment(tripDateString, 'YYYY-MM-DD');

        if (tripDate.isBefore(earliestDate)) {
            earliestDate = tripDate;
        }
        if (tripDate.isAfter(latestDate)) {
            latestDate = tripDate;
        }

        const tripDateKey = tripDate.format('YYYY-MM-DD');
        if (!tripsPerDay[tripDateKey]) {
            tripsPerDay[tripDateKey] = 0;
        }
        tripsPerDay[tripDateKey]++;
    });



    const totalIssues = Object.values(tripsPerDay).reduce((acc, count) => acc + count, 0);
    const uniqueDays = Object.keys(tripsPerDay).length;

    const avgIssuesPerDay = totalIssues / uniqueDays;

    return `${
        Math.round(avgIssuesPerDay * 100) / 100
    } issues/day`;
},
      avgPerDay() {
        let trips = this.totalTrips;
        if (!trips || !trips.length) return '0%';
        const tripsWithZeroWait = trips.filter(
          (trip) => trip.wait === 'No'
        );
        const perDay =
          tripsWithZeroWait.length / this.selectedDaysArray.length ||
          1;

        return `${Math.round(perDay * 100) / 100}%`;
      },
      reportedTripsPayload() {
        let trips = this.totalTrips;
        if (!trips || !trips.length) {
          return {
            total: '0',
            avgTripDuration: 'N/A',
            busiestDayOfWeek: 'N/A',
            avgWaitTime: 'N/A',
          };
        }

        const totalTripDur = trips.reduce((acc, trip) => {
          if (!trip.tripDur) return acc;
          const tripDurMoment = moment.duration(trip.tripDur);
          return acc + tripDurMoment.asSeconds();
        }, 0);

        const avgTripDur = totalTripDur / trips.length;
        const duration = moment.duration(avgTripDur, 'seconds');
        const formattedDuration = moment
          .utc(duration.asMilliseconds())
          .format('HH:mm:ss');

        const weekDays = trips.map((trip) => trip.weekDay || '');
        const busiestDayOfWeek = this.mostFrequentValue(weekDays);

        const totalWaitTime = trips.reduce((acc, trip) => {
          if (!trip.waitTime) {
            return acc;
          }
          const waitTimeMoment = moment.duration(trip.waitTime);
          return acc + waitTimeMoment.asSeconds();
        }, 0);

        const avgWaitTime = totalWaitTime / trips.length;
        const avgDuration = moment.duration(avgWaitTime, 'seconds');
        const formattedWaitTime = moment
          .utc(avgDuration.asMilliseconds())
          .format('HH:mm:ss');

        return {
          total: trips.length,
          avgTripDuration: formattedDuration,
          busiestDayOfWeek: busiestDayOfWeek.value,
          avgWaitTime: formattedWaitTime,
        };
      },
      violationReportsPayload() {
        if (!this.totalTrips?.length)
          return {
            mostType: {
              value: 'N/A',
              count: 0,
            },
            mostWeekDay: {
              value: 'N/A',
              count: 0,
            },
            mostTimeOfDay: {
              value: 'N/A',
              count: 0,
            },
          };
        let trips = this.totalTrips?.filter(
          (trip) => trip.state === 'Violation'
        );

        const types = trips.map((trip) => trip.type);
        const weekDays = trips.map((trip) => trip.weekDay);
        const startTimes = trips.map((trip) =>
          new Date(trip.startTime).getHours()
        );

        const mostType = this.mostFrequentValue(types);

        const mostWeekDay = this.mostFrequentValue(weekDays);

        const mostTimeOfDay = this.mostFrequentValue(
          startTimes.map(this.getTimeOfDay)
        );

        return {
          total: trips.length,
          mostType,
          mostWeekDay,
          mostTimeOfDay,
        };
      },
      loggedTripsPayload() {
        if (!this.totalTrips?.length) return {};
        const manual = this.totalTrips?.filter(
          (trip) => trip.type === 'Manual Logger'
        );
        const automatic = this.totalTrips?.length - manual?.length;

        return { manual: manual?.length, automatic };
      },
      tripsWithWaitCount() {
        const tripsWithWait = this.totalTrips?.filter(
          (trip) => trip.wait === 'Yes'
        );
        return tripsWithWait?.length || 0;
      },
      totalDevicesCount() {
        return this.firebasePayload.totalDevicesCount || 0;
      },
      abandonedTripsPayload() {
        if (!this.totalTrips?.length) return {};
        const abondonedTrips = this.totalTrips?.filter(
          (trip) => trip.state === 'Abandon'
        );

        const totalAmount = abondonedTrips.reduce(
          (acc, trip) => (acc += +trip.tripExpend.split('$')[1]),
          0
        );
        return { count: abondonedTrips.length, totalAmount };
      },
      violationReport() {
        if (!this.totalTrips?.length) return {};

        let trips = this.totalTrips?.filter(
          (trip) => trip.state === 'Violation'
        );

        const placardTrips = trips.filter(
          (trip) => trip.violationType === 'No Placard'
        );
        const suspectedMisuse = trips.filter(
          (trip) => trip.violationType === 'Suspected Placard Misuse'
        );
        const illegallyParked = trips.filter(
          (trip) => trip.violationType === 'Illegally Parked'
        );
        if (this.selectedEventType !== 'All') {
          trips = (this.totalTrips || [])?.filter(
            (trip) => trip.violationType === this.selectedEventType
          );
        }

        return {
          trips,
          placardCount: placardTrips.length,
          suspectedMisuseCount: suspectedMisuse.length,
          illegallyParkedCount: illegallyParked.length,
        };
      },
      selectedEventType: {
        get() {
          return this.eventType;
        },
        set(value) {
          this.eventType = value;
        },
      },
      selectedState: {
        get() {
          return this.state;
        },
        set(value) {
          this.state = value;
        },
      },
      selectedDaysArray: {
        get() {
          return this.daysArray.map((day) => day.toLowerCase());
        },
        set(value) {
          this.daysArray = value;
        },
      },
      selectedUserAge: {
        get() {
          return this.selectedAge;
        },
        set(value) {
          this.selectedAge = value;
        },
      },
      selectedStartTimeFilter: {
        get() {
          return this.selectedStartTime;
        },
        set(value) {
          this.selectedStartTime = value;
        },
      },
      selectedEndTimeFilter: {
        get() {
          return this.selectedEndTime;
        },
        set(value) {
          this.selectedEndTime = value;
        },
      },
    },
    methods: {
      isBetweenStartAndEndTime(tripStartTime) {
        const tripStartDate =
          moment(tripStartTime).format('YYYY-MM-DD');
        const tripTime = moment(tripStartTime).format('HH:mm');

        const startTime = this.selectedStartTimeFilter;
        const endTime = this.selectedEndTimeFilter;

        const startDate = moment(
          tripStartDate + ' ' + startTime,
          'YYYY-MM-DD HH:mm'
        );
        const endDate = moment(
          tripStartDate + ' ' + endTime,
          'YYYY-MM-DD HH:mm'
        );

        const tripTimeMoment = moment(
          tripStartDate + ' ' + tripTime,
          'YYYY-MM-DD HH:mm'
        );

        return tripTimeMoment.isBetween(startDate, endDate);
      },
      getTimeOfDay(hour) {
        if (hour >= 5 && hour < 12) {
          return 'Morning';
        } else if (hour >= 12 && hour < 18) {
          return 'Afternoon';
        } else {
          return 'Evening/Night';
        }
      },
      mostFrequentValue(array) {
        const counts = {};
        let maxCount = 0;
        let mostFrequentValue = null;

        array.forEach((item) => {
          if (counts[item] === undefined) {
            counts[item] = 1;
          } else {
            counts[item]++;
          }

          if (counts[item] > maxCount) {
            maxCount = counts[item];
            mostFrequentValue = item;
          }
        });

        return { value: mostFrequentValue || 'N/A', count: maxCount };
      },
      onQuickViewResetClick() {
        return (this.isQuickView = false);
      },
      onQuickViewClick() {
        return (this.isQuickView = true);
      },
      isSelectedDay(day) {
        return this.selectedDays.includes(day);
      },
      selectDay(day) {
        if (this.isSelectedDay(day)) {
          // Remove day if already selected
          this.selectedDays = this.selectedDays.filter(
            (selectedDay) => selectedDay !== day
          );
        } else {
          // Add day if not selected
          this.selectedDays.push(day);
        }
      },
      updateStartDate() {
        // Add your logic to handle the start date update
        const startDate = `${this.selectedYear}-${this.getMonthNumber(
          this.selectedMonth
        )}-${this.selectedDate}`;
        console.log('Start Date:', startDate);
      },
      getMonthNumber(month) {
        // Helper method to get the month number based on its name
        return this.months.indexOf(month) + 1;
      },
      toggleOption(option, index, selectedArray) {
        selectedArray[index] = !selectedArray[index];
      },
      toggleStateOption(option, index, selectedArray) {
        selectedArray[index] = !selectedArray[index];
      },
      toggleTimeOption(option) {
        this.selectedTime = option;
      },
      toggleAgeOption(option) {
        this.selectedAge = option;
      },
      toggleDateRangeOption(option) {
        this.selectedDateRange = option;
      },
      isStateOptionSelected(option) {
        return this.selectedStateFilters.includes(option);
      },

      // Method to handle checkbox click
      handleStateCheckboxClick(option) {
        if (this.selectedStateFilters.includes(option)) {
          // Option is already selected, remove it
          this.selectedStateFilters =
            this.selectedStateFilters.filter(
              (item) => item !== option
            );
        } else {
          // Option is not selected, add it
          this.selectedStateFilters.push(option);
        }
      },
      isOptionSelected(option) {
        return this.selectedFilters.includes(option);
      },

      // Method to handle checkbox click
      handleCheckboxClick(option) {
        if (this.selectedFilters.includes(option)) {
          // Option is already selected, remove it
          this.selectedFilters = this.selectedFilters.filter(
            (item) => item !== option
          );
        } else {
          // Option is not selected, add it
          this.selectedFilters.push(option);
        }
      },
      toggleDrawer() {
        this.showDrawer = !this.showDrawer;
      },

      // Close the drawer
      closeDrawer() {
        this.showDrawer = false;
      },
      startDateChanged(startDate) {
        this.selectedStartDate = startDate;
        this.fetchData();
      },
      endDateChanged(endDate) {
        this.selectedEndDate = endDate;
        this.fetchData();
      },
      eventTypeChanged(option) {
        this.selectedEventType = option;
      },
      tripStateChanged(option) {
        this.selectedState = option;
      },
      selectedDaysArrayChanged(option) {
        this.selectedDaysArray = option;
      },
      dateRangeSelected(option) {
        if (this.dateRange.includes(option)) {
          const today = moment();
          switch (option) {
            case 'This Week':
              this.selectedStartDate = today.clone().startOf('week');
              this.selectedEndDate = today.clone().endOf('week');
              break;
            case 'This Month':
              this.selectedStartDate = today.clone().startOf('month');
              this.selectedEndDate = today.clone().endOf('month');
              break;
            case 'Last Month':
              this.selectedStartDate = today
                .clone()
                .subtract(1, 'month')
                .startOf('month');
              this.selectedEndDate = today
                .clone()
                .subtract(1, 'month')
                .endOf('month');
              break;
            case 'Year to Date':
              this.selectedStartDate = today.clone().startOf('year');
              this.selectedEndDate = today.clone().endOf('day');
              break;
          }
          this.selectedStartDate =
            this.selectedStartDate.format('MM/DD/YY');
          this.selectedEndDate =
            this.selectedEndDate.format('MM/DD/YY');

          this.fetchData();
        }
      },
      userAgeChanged(option) {
        this.selectedUserAge = option;
      },
      startTimeChanged(option) {
        this.selectedStartTime = option;
      },
      endTimeChanged(option) {
        this.selectedEndTime = option;
      },

      fetchData() {
        if (Object.entries(FirebaseService)?.length <= 0) {
          return;
        }
        const start = moment(this.selectedStartDate, 'MM-DD-YY');
        const end = moment(this.selectedEndDate, 'MM-DD-YY');
        if (start.isBefore(end) || start.isSame(end)) {
          FirebaseService.getDashboardData(
            this.selectedStartDate,
            this.selectedEndDate
          ).then((response) => {
            this.firebasePayload = response;
          });
        }
      },
    },
    mounted() {
      FirebaseService = inject('firestore');
      FirebaseService;
      this.fetchData();
    },
  };
</script>

<style scoped>
  /* Add your component-specific styles here if needed */
  .dashboard-style-5 {
    /* Add styles for the outer container */
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .custom-checkbox {
    /* Hide default checkbox */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    /* Use custom icon font */
    font-family: 'Font Awesome 5 Free';
    /* Set the icon color and size */
    color: green;
    font-size: 16px;
    /* Add some styles to simulate a checkbox */
    width: 20px;
    height: 20px;
    border: 1px solid #000;
    border-radius: 4px;
    cursor: pointer;
    /* Center the icon within the checkbox */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .drawer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    background: #122e60;
    color: white;
    padding: 30px 25px;
  }
  .drawer-header .h3 {
    font-family: Jost;
    font-size: 21.955px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    font-size: 21.955px;
  }
  .drawer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    color: white;
    /* padding: 20px; */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 99999999;
  }

  /* Style the close button */
  .close-button {
    cursor: pointer;
    margin-top: 10px;
    /* padding: 10px; */
    /* background-color: #fff; */
    /* color: #333; */
    border-radius: 5px;
    cursor: pointer;
    padding: 10px;
    /* background-color: #fff; */
    /* color: #333; */
    border-radius: 5px;
    color: white;
    padding-right: 32px;
    font-family: Jost;
    font-size: 21.955px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    font-size: 21.955px;
  }
  .card {
    width: 85%;
    margin: 20px auto;
    padding: 0px !important;
    box-sizing: border-box;
    background-color: #f7f7f7;
    border-radius: 10px;
  }
  .card-container {
    padding: 15px 0px 0px;
    border: 0.5px solid #707a8c;
  }
  .ListCard {
    padding: 14px 20px;
    /* border-bottom: 0.2px solid #707a8c; */
    width: 100%;
  }
  .selected-day {
    background-color: blue;
    border-radius: 8px;
    color: white; /* Add appropriate text color for better visibility */
    padding: 5px 10px;
    cursor: pointer;
  }
  /* Add other styles based on your component structure */
</style>
