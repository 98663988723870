<template>
  <div class="last_Desktop">
    <div class="lastInner_Desktop card" style="padding-bottom: 45px">
      <div class="lastOne_Desktop">
        <div class="lastOne_left">
          <h2 style="font-size: 30px; font-weight: 600">Violation Report</h2>
          <!-- <img src="./images/three.png" alt="" /> -->
          <div
            style="position: relative; margin: 0 auto"
            class="graph"
          >
            <VoilationChart :violationReport="violationReport" />
          </div>
        </div>
        <div class="lastOne_left frame_desktop">
          <!-- <div class="reportIcon_Desktop" style="font-weight: bold">
            <div class="repo_Desktop">
              <p>Total</p>
            </div>
            <p>565</p>
          </div> -->
          <div class="reportIcon_Desktop">
            <div class="repo_Desktop">
              <img :src="frame48" alt="" />

              <p style="font-weight: 600">No placard</p>
            </div>
            <p style="font-weight: 600">
              {{ violationReport.placardCount || 0 }}
            </p>
          </div>
          <div class="reportIcon_Desktop">
            <div class="repo_Desktop">
              <img :src="frame51" alt="" />

              <p style="font-weight: 600">Suspected Misuse</p>
            </div>
            <p style="font-weight: 600">
              {{ violationReport.suspectedMisuseCount || 0 }}
            </p>
          </div>
          <div class="reportIcon_Desktop">
            <div class="repo_Desktop">
              <img :src="frame523" alt="" />

              <p style="font-weight: 600">Parked illegally</p>
            </div>
            <p style="font-weight: 600">
              {{ violationReport.illegallyParkedCount || 0 }}
            </p>
          </div>
        </div>
      </div>
      <div class="lastTwo_Desktop">
        <div
          v-if="violationTrips.length > 0"
          id="myCarousel"
          class="carousel carousel_main slide"
          data-ride="carousel"
        >
          <ol class="carousel-indicators pagi">
            <li
              v-for="(trip, index) in violationTrips"
              :key="index"
              data-target="#myCarousel"
              :data-slide-to="index"
              class="dot"
              :class="{ active: trip.isActive }"
            ></li>
          </ol>
          <div class="carousel-inner">
            <div
              v-for="(trip, index) in violationTrips"
              :key="index"
              class="item"
              :class="{ active: trip.isActive }"
            >
              <div class="firstSlide">
                <div class="firstSlide_img" style="gap: 8px">
                  <img
                    :src="
                      trip.image ||
                      require('../../assets/no-image.jpeg')
                    "
                    alt="Los Angeles"
                    style="width: 100%"
                  />
                  <img
                    :src="
                      trip.image2 ||
                      require('../../assets/no-image.jpeg')
                    "
                    alt="Los Angeles"
                    style="width: 100%"
                  />
                </div>
                <div class="frame20">
                  <div class="reportIcon_Desktop">
                    <div class="repo_Desktop">
                      <img :src="frame52" alt="" />
                      <p>Date</p>
                    </div>
                    <p>{{ getDate(trip.startTime) }}</p>
                  </div>
                  <div class="reportIcon_Desktop">
                    <div class="repo_Desktop">
                      <img :src="clock" alt="" />
                      <p>Time</p>
                    </div>
                    <p>{{ getTime(trip.startTime) }}</p>
                  </div>
                  <div class="reportIcon_Desktop">
                    <div class="repo_Desktop">
                      <img :src="frame521" alt="" />

                      <p>Type</p>
                    </div>
                    <p>{{ trip.type }}</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="item">
              <div class="firstSlide">
                <div class="firstSlide_img">
                  <img :src="frame" alt="Los Angeles" />
                  <img :src="frame" alt="Los Angeles" />
                </div>
                <div class="frame20">
                  <div class="reportIcon_Desktop">
                    <div class="repo_Desktop">
                      <img :src="frame52" alt="" />
                      <p>Date</p>
                    </div>
                    <p>{{ currentDate }}</p>
                  </div>
                  <div class="reportIcon_Desktop">
                    <div class="repo_Desktop">
                      <img :src="clock" alt="" />
                      <p>Time</p>
                    </div>
                    <p>02:30 PM</p>
                  </div>
                  <div class="reportIcon_Desktop">
                    <div class="repo_Desktop">
                      <img :src="frame521" alt="" />

                      <p>Type</p>
                    </div>
                    <p>{{ currentType }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="firstSlide">
                <div class="firstSlide_img">
                  <img :src="frame" alt="Los Angeles" />
                  <img :src="frame" alt="Los Angeles" />
                </div>
                <div class="frame20">
                  <div class="reportIcon_Desktop">
                    <div class="repo_Desktop">
                      <img :src="frame52" alt="" />
                      <p>Date</p>
                    </div>
                    <p>{{ currentDate }}</p>
                  </div>
                  <div class="reportIcon_Desktop">
                    <div class="repo_Desktop">
                      <img :src="clock" alt="" />
                      <p>Time</p>
                    </div>
                    <p>02:30 PM</p>
                  </div>
                  <div class="reportIcon_Desktop">
                    <div class="repo_Desktop">
                      <img :src="frame521" alt="" />

                      <p>Type</p>
                    </div>
                    <p>{{ currentType }}</p>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
          <a
            class="left carousel-control"
            href="#myCarousel"
            data-slide="prev"
          >
            <span class="glyphicon glyphicon-chevron-left"> </span>
            <span class="sr-only">Previous</span>
          </a>
          <a
            class="right carousel-control"
            href="#myCarousel"
            data-slide="next"
          >
            <span class="glyphicon glyphicon-chevron-right"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
        <div v-else>
          <strong style="font-size: 18px"
            >No Violation Trips Found!!</strong
          >
        </div>
      </div>
    </div>
  </div>
  <!-- <section class="frame-section">
    <div class="frame-parent3">
      <div class="frame20">
        <div class="frame21">
          <button class="ellipse-parent">
            <div class="group-child"></div>
            <div class="group-item"></div>
            <div class="group-inner"></div>
          </button>
          <div class="frame-parent4">
            <div class="frame-parent5">
              <img class="frame-icon" alt="" :src="frame52" />
              <div class="date">Date</div>
              <div class="div8">22/10/2023</div>
            </div>
            <div class="frame-parent6">
              <img class="frame-icon" alt="" :src="frame521" />
              <div class="date">Type</div>
              <div class="parked-illegally">Parked Illegally</div>
            </div>
            <div class="frame-parent7">
              <img class="frame-child2" alt="" :src="frame522" />
              <div class="date">Images</div>
              <div class="div9">5</div>
            </div>
            <img class="frame-icon1" alt="" :src="frame" />
          </div>
        </div>
      </div>
      <div class="frame22">
        <div class="frame23">
          <h2 class="violation-report">Violation Report</h2>
          <img class="frame-child3" alt="" :src="frame48" />
          <img class="frame-child4" alt="" :src="frame51" />
          <div class="no-placard">No Placard</div>
          <img class="frame-child5" alt="" :src="frame523" />
          <div class="div10">270</div>
          <div class="suspected-misuse">Suspected Misuse</div>
          <div class="parked-illegally1">Parked Illegally</div>
          <div class="div11">180</div>
          <div class="div12">100</div>
          <div class="frame-parent8">
            <div class="frame24">
              <VoilationChart />
            </div>
          </div>
        </div>
        <img class="frame-child7" alt="" :src="vector49" />
      </div>
    </div>
    <div class="group-parent">
      <img class="group-icon" alt="" :src="group127" />
      <h2 class="events">Events</h2>
      <div class="frame-parent10">
        <div class="type-parked-illegally-parent">
          <div class="type-parked-illegally-container">
            <span>
              <span class="location">Type:</span>
            </span>
            <span class="span">
              <span> </span>
              <span>Parked Illegally</span>
            </span>
          </div>
          <div class="location-40680150-7392554-container">
            <span>
              <span class="location">Location:</span>
            </span>
            <span class="span">
              <span> </span>
              <span>40.680150, -73.925540</span>
            </span>
          </div>
          <div class="address-147-marvon-container">
            <p class="address-147-marvon-avenue">
              <span class="address">
                <span class="address1">Address:</span>
              </span>
              <span class="marvon-avenue">
                <span class="address"> </span>
                <span>147 Marvon Avenue</span>
              </span>
            </p>
            <p class="address-147-marvon-avenue">
              <span class="marvon-avenue">
                <span> Brooklyn, NY 11233</span>
              </span>
            </p>
          </div>
        </div>
        <img class="polygon-icon" alt="" :src="polygon1" />
      </div>
      <div class="frame30"><img class="" alt="" :src="map" />{" "}</div>
    </div>
  </section> -->
</template>

<script>
  import frame523 from '../../assets/frame-523.svg';
  import frame48 from '../../assets/frame-48.svg';
  import frame51 from '../../assets/frame-51.svg';
  import vector49 from '../../assets/vector-49.svg';
  import polygon1 from '../../assets/polygon-1.svg';
  import group127 from '../../assets/group-127.svg';
  import frame from '../../assets/car.png';
  import frame522 from '../../assets/frame-522.svg';
  import frame521 from '../../assets/frame-521.svg';
  import frame52 from '../../assets/frame-52.svg';
  import map from '../../assets/map.png';
  import clock from '../../assets/clock.png';
  import VoilationChart from '@/components/chats//VoilationChart.vue';
  import moment from 'moment';

  export default {
    components: {
      VoilationChart,
    },
    props: {
      violationReport: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        currentIndex: 0,
        currImgIndex: 0,
        images: [
          {
            src: frame,
          },
          {
            src: map,
          },
          {
            src: frame,
          },
        ],
        imageItems: [
          {
            src: frame,
            label: 'No placard',
            date: '22 / 10 / 2023',
            type: 'No Placard',
            count: 5,
          },
          {
            src: map,
            label: 'Suspected Misuse',
            date: '10 / 10 / 2023',
            type: 'Suspected Misuse',
            count: 2,
          },
          {
            src: frame,
            label: 'Parked illegally',
            date: '2 / 10 / 2022',
            type: 'Parked illegally',
            count: 10,
          },
        ],
        frame523,
        frame48,
        frame51,
        vector49,
        polygon1,
        group127,
        frame,
        frame522,
        frame521,
        frame52,
        map,
        clock,
      };
    },
    computed: {
      currentImage() {
        return this.images[this.currImgIndex].src;
      },
      currentDate() {
        return this.imageItems[this.currentIndex].date;
      },
      currentType() {
        return this.imageItems[this.currentIndex].type;
      },
      currentCount() {
        return this.imageItems[this.currentIndex].count;
      },
      violationTrips() {
        if (!this.violationReport.trips?.length) {
          return [];
        }
        let trips = this.violationReport.trips.map((trip) => ({
          ...trip,
          isActive: false,
        }));
        trips[0].isActive = true;
        return trips;
      },
    },
    methods: {
      changeImage(index) {
        this.currImgIndex = index;
      },
      changeImageData(index) {
        this.currentIndex = index;
      },
      getDate(dateTime) {
        const dateTimeObj = moment(dateTime);
        return dateTimeObj.format('YYYY-MM-DD');
      },
      getTime(dateTime) {
        const dateTimeObj = moment(dateTime);
        return dateTimeObj.format('hh:mm:ss A');
      },
    },
  };
</script>

<style scoped>
  .slider {
    position: relative;
    overflow: hidden;
  }

  .slider img {
    width: 320px;
    height: 320px;
    transition: transform 0.5s ease-in-out;
    object-fit: cover;
  }

  .reportIcons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .navigation-dots {
    margin-top: 10px;
    text-align: center;
  }

  .navigation-dots span {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ccc;
    margin: 0 5px;
    cursor: pointer;
  }

  .navigation-dots span.active {
    background-color: #333;
  }
  /* Add your component-specific styles here if needed */
  /* Add styles based on the provided class names in the original React component */
</style>
