/* eslint-disable no-unused-vars */
import { database } from '@/firebase';
import { ref, onValue, update, remove, get, child } from 'firebase/database';

const FirebasePath = 'Dashboard';

class FirebaseService {
  allUsers = [];
  violationTrips = [];
  allTrips = [];
  allNowayadaCount = 0;

  async getDashboardData(startDate, endDate) {
    const dashboardRef = ref(database, FirebasePath);
    return new Promise((resolve, reject) => {
      onValue(
        dashboardRef,
        async (snapshot) => {
          const data = snapshot.val();
          this.violationTrips = Object.entries(
            data.Violation_Trips
          ).map(([id, obj]) => ({ id, ...obj }));
          this.allUsers = Object.entries(data.Users).map(
            ([id, obj]) => ({
              id,
              ...obj,
            })
          );

          const totalTrips = [];
          const deviceIds = {};
          await Promise.all(this.allUsers.map(async (userObj) => {  
            if (userObj.Trips) {
              const trips = await this.TripsFromUserObject(
                userObj,
                startDate,
                endDate
              );
              if (trips.length) {
                totalTrips.push(...trips);
                deviceIds[`${userObj.id}`] = userObj.Profile.userName;
              }
            }
          }));
          this.allTrips = totalTrips;

          resolve({
            totalTrips: this.allTrips,
            totalTripsCount: this.getTotalTrips(),
            totalDevicesCount: Object.keys(deviceIds).length,
            abandonedTrips: data.abandoned_trips,
            reportedTripsPayload: data.reported_trips,
            violationReportsPayload: data.reported_violations,
            violationTrips: this.violationTrips,
            nowayadaCount: this.allNowayadaCount
          });
        },
        (error) => {
          console.error(error.message || error);
          reject(error);
        }
      );
    });
  }

  async TripsFromUserObject(userObj, startDate, endDate) {
    const tripsInRange = [];
    if (userObj && userObj.Trips) {
      if (startDate && endDate) {
        const [startMonth, startDay, startYear] =
          startDate.split('/');
        const [endMonth, endDay, endYear] = endDate.split('/');

        let currentDate = new Date(
          `20${startYear}`,
          startMonth - 1,
          startDay
        );
        const endDateObj = new Date(
          `20${endYear}`,
          endMonth - 1,
          endDay
        );

        while (currentDate <= endDateObj) {
          const year = currentDate.getFullYear();
          let month = currentDate.getMonth() + 1;
          let day = currentDate.getDate();
          if (month < 10) month = `0${+month}`;
          if (day < 10) day = `0${+day}`;

          let trip = null;
          if (
            userObj.Trips[year] &&
            userObj.Trips[year][month] &&
            userObj.Trips[year][month][day]
          ) {
            trip = userObj.Trips[year][month][day];
          }
          if (trip) {
            let snapshot = await get(ref(database, `ADA_Parking_Violations/Users/${userObj.id}`))

            let nowayada = [];
            if(snapshot.exists()) {
              nowayada = [...Object.entries(snapshot.val()).map((item) => ({
                id: item[0],
                ...item[1]
              }))];
            }
            this.allNowayadaCount += nowayada.length;
            tripsInRange.push(
              ...Object.entries(trip).map((item) => ({
                id: item[0],
                year,
                month,
                day,
                ...item[1],
                userAge: userObj.Profile.age,
              })),
              ...nowayada
            );
          }

          currentDate.setDate(currentDate.getDate() + 1);
        }
      }
    }
    return tripsInRange;
  }

  getTotalTrips() {
    return this.allTrips.length;
  }
}

export default new FirebaseService();
